import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  width: calc(100% - 40px);
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: ${props => (props.selected ? '#EEEFF0' : 'white')};
`;
const ProfilePic = styled.div`
  height: 40px;
  width: 40px;
  background-color: black;
  border-radius: 20px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const NameText = styled.span`
  color: #202122;
  font-weight: 700;
  font-size: 14px;
`;
const EmailText = styled.span`
  color: #b1b7c1;
  font-size: 13px;
  margin-top: 2px;
`;

const User = ({ user, selected, onSelect }) => {
  const handleSelect = () => onSelect(user);

  return (
    <Container {...{ selected }} onClick={handleSelect}>
      <ProfilePic />
      <Right>
        <NameText>{user.name}</NameText>
        <EmailText>{user.email}</EmailText>
      </Right>
    </Container>
  );
};

export default User;
