import _ from 'lodash';
import React, { useRef, useEffect, useReducer } from 'react';
import Context from './Context';
import { useDatabase } from '../data/database';
import sync from '../data/sync';
import createReducer from '../utils/createReducer';
import call from '../utils/call';

const events = {
  SIGNED_IN: 'SIGNED_IN',
  FIRST_SYNC_COMPLETE: 'FIRST_SYNC_COMPLETE',
  SIGNED_OUT: 'SIGNED_OUT',
};

const _session = JSON.parse(localStorage.getItem('session'));
const initialState = {
  signedIn: !!_session,
  firstSyncComplete: false,
  session: _session,
};
const reducer = createReducer({
  [events.SIGNED_IN](state, event) {
    const { session } = event.payload;
    return {
      ...state,
      signedIn: true,
      session,
    };
  },

  [events.FIRST_SYNC_COMPLETE](state) {
    return {
      ...state,
      firstSyncComplete: true,
    };
  },
  [events.SIGNED_OUT](state) {
    return {
      ...state,
      signedIn: false,
    };
  },
});

export default ({ children }) => {
  const database = useDatabase();
  const [state, dispatch] = useReducer(reducer, initialState);

  const syncRef = useRef(false);
  const syncCount = useRef(0);

  // This effect is for syncing when changes are made
  useEffect(() => {
    const syncWrapper = async () => {
      if (syncRef.current) {
        return;
      }

      syncRef.current = true;

      await sync();

      syncRef.current = false;
      syncCount.current = syncCount.current + 1;

      if (syncCount.current === 1) {
        dispatch({ type: events.FIRST_SYNC_COMPLETE });
      }
    };

    if (state.signedIn) {
      syncWrapper();
      const syncInterval = setInterval(syncWrapper, 5000);
      const tableChanges = database.withChangesForTables(['events']).subscribe(_.throttle(syncWrapper, 1000));
      // add other tables to list

      return () => clearInterval(syncInterval) && tableChanges.unsubscribe();
    }
  }, [state.signedIn]);

  // TODO: This effect is for maintaing the expansion of recurring events
  useEffect(() => {
    if (state.signedIn) {
      // grab lastExpanded timestamp, if that was >= 24 hours ago then run the expansion again.
      // Query db to remove duplicate generated events and do not overwrite "real" events.
    }
  }, [state.signedIn]);

  const actions = {
    signIn: async ({ email, password }) => {
      const session = await call('POST', 'coaches/auth', { email, password });
      const coach = await call('GET', `coaches/${session.coachId}`);

      localStorage.setItem('session', JSON.stringify(session));

      await database.action(async () => {
        await database.collections.get('coach').create(record => {
          record._raw.id = 'COACH';
          record.coachId = coach.id;
          record.email = coach.email;
          record.name = coach.name;

          record._raw._status = 'synced';
        });
      });

      await sync();

      dispatch({ type: events.SIGNED_IN, payload: { session } });
    },
    signOut: async () => {
      dispatch({ type: events.SIGNED_OUT });

      localStorage.removeItem('session');

      database.action(async () => {
        await database.unsafeResetDatabase();
      });
    },
  };

  const value = { ...state, ...actions };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
