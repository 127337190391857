import { Model, Q } from '@nozbe/watermelondb';
import { field, json } from '@nozbe/watermelondb/decorators';
import * as CalendarLib from '../../lib/calendar';

const FIELDS = [
  'id',
  'userId',
  'eventId',
  'calendarId',
  'rootEventId',
  'recurringEventId',
  'rootRecurringEventId',
  'isAllDay',
  'start',
  'end',
  'recurrence',
  'timezone',
  'status',
  'summary',
  'organizer',
  'location',
  'description',
  'conference',
  'reminders',
  'color',
  'attendees',
  'responseStatus',
  'eventType',
  'archived',
  'subtasks',
  'icon',
  'value',
  'goal',
  'unit',
  'time',
  'order',
  'lastModified',
  '_generated',
];
const EDITABLE_FIELDS = FIELDS.filter(f => !['id', 'userId', 'eventId', 'lastModified'].includes(f));
const parseRaw = rawEvent => {
  const parsed = {};

  for (let i = 0; i < FIELDS.length; i++) {
    parsed[FIELDS[i]] = rawEvent[FIELDS[i]];
  }

  return parsed;
};

class Event extends Model {
  static table = 'events';

  @field('userId') userId;
  @field('eventId') eventId;
  @field('calendarId') calendarId;
  @field('rootEventId') rootEventId;
  @field('recurringEventId') recurringEventId;
  @field('rootRecurringEventId') rootRecurringEventId;

  @field('isAllDay') isAllDay;
  @field('start') start;
  @field('end') end;
  @json('recurrence', r => r) recurrence;
  @field('timezone') timezone;
  @field('status') status;

  @field('summary') summary;
  @json('organizer', r => r) organizer;
  @field('location') location;
  @field('description') description;
  @json('reminders', r => r) reminders;
  @field('color') color;
  @json('attendees', r => r) attendees;
  @field('responseStatus') responseStatus;

  @field('eventType') eventType;
  @field('archived') archived;
  @json('conference', r => r) conference;
  @json('subtasks', r => r) subtasks;
  @field('icon') icon;
  @field('value') value;
  @field('goal') goal;
  @field('unit') unit;
  @field('time') time;

  @field('order') order;
  @field('lastModified') lastModified;
  @field('_generated') _generated;

  static async createEvent(formData, database) {
    const data = { ...formData, eventType: 'event', archived: false, responseStatus: 'accepted' };

    await Event._create(data, database);
  }

  static async editEvent(id, formData, database) {
    await Event._edit(id, formData, database);
  }

  static async editRecurringEventFromMarker(id, formData, markerId, database) {
    await Event._editFromMarker(id, formData, markerId, database);
  }

  static async createTask(formData, database) {
    const data = {
      ...formData,
      eventType: 'task',
      archived: false,
      responseStatus: 'accepted',
      attendees: [],
    };

    await Event._create(data, database);
  }

  static async editTask(id, formData, database) {
    await Event._edit(id, formData, database);
  }

  static async editRecurringTaskFromMarker(id, formData, markerId, database) {
    await Event._editFromMarker(id, formData, markerId, database);
  }

  static async createRoutine(formData, database) {
    const data = {
      ...formData,
      eventType: 'routine',
      archived: false,
      responseStatus: 'accepted',
      timezone: 'LOCAL', // TODO: remove
      attendees: [],
      value: 0,
    };

    await Event._create(data, database);
  }

  static async editRoutine(id, formData, markerId, database) {
    await Event._editFromMarker(id, formData, markerId, database);
  }

  static async editRoutineInstance(id, formData, database) {
    await Event._edit(id, formData, database);
  }

  static async _create(data, database) {
    // data should hold the userId as well
    const { timezone: deviceTimezone } = await database.collections.get('user').find('USER');

    const eventUpdates = CalendarLib.createEvent({ data }, deviceTimezone);

    const created = eventUpdates.map(data => ({ data }));

    await database.action(async () => {
      await database.batch(
        ...created.map(({ data }) =>
          database.collections.get('events').prepareCreate(record => {
            record._raw.id = data.id;

            if (data._generated) {
              record._raw._status = 'synced';
            }

            for (let i = 0; i < EDITABLE_FIELDS.length; i++) {
              record[EDITABLE_FIELDS[i]] = data[EDITABLE_FIELDS[i]];
            }
          }),
        ),
      );
    });
  }

  static async _edit(id, data, database) {
    const { timezone: deviceTimezone } = await database.collections.get('user').find('USER');

    const eventModel = (await database.collections.get('events').query(Q.where('id', id)))[0];
    const event = parseRaw(eventModel);

    const created = [];
    const updated = [];

    if (event.recurringEventId) {
      const chainedRecurringEventModels = await database.collections
        .get('events')
        .query(Q.where('rootEventId', event.rootRecurringEventId));
      const chainedInstanceModels = await database.collections
        .get('events')
        .query(Q.where('rootRecurringEventId', event.rootRecurringEventId));
      const eventModels = [...chainedRecurringEventModels, ...chainedInstanceModels];

      const chainedRecurringEvents = chainedRecurringEventModels.map(parseRaw).sort((a, b) => (b.id < a.id ? 1 : -1));
      const chainedInstances = chainedInstanceModels.map(parseRaw).sort((a, b) => (b.id < a.id ? 1 : -1));

      // change calendar lib to handle new id names
      const eventUpdates = CalendarLib.editEventInstance({ chainedRecurringEvents, chainedInstances }, { id, data });

      for (let i = 0; i < eventUpdates.length; i++) {
        const eventUpdate = eventUpdates[i];
        const model = eventModels.find(m => m.id === eventUpdate.id);

        if (model) {
          updated.push({ model, data: eventUpdate });
        } else {
          created.push({ data: eventUpdate });
        }
      }
    } else {
      const eventUpdates = CalendarLib.editEvent({ event }, { data }, deviceTimezone);

      for (let i = 0; i < eventUpdates.length; i++) {
        const eventUpdate = eventUpdates[i];
        if (eventUpdate.id === id) {
          updated.push({ model: eventModel, data: eventUpdate });
        } else {
          created.push({ data: eventUpdate });
        }
      }
    }

    await database.action(async () => {
      await database.batch(
        ...created.map(({ data }) =>
          database.collections.get('events').prepareCreate(record => {
            record._raw.id = data.id;

            if (data._generated) {
              record._raw._status = 'synced';
            }

            for (let i = 0; i < EDITABLE_FIELDS.length; i++) {
              record[EDITABLE_FIELDS[i]] = data[EDITABLE_FIELDS[i]];
            }
          }),
        ),
        ...updated.map(({ model, data }) =>
          model.prepareUpdate(record => {
            if (data._generated) {
              record._raw._status = 'synced';
            } else if (record._generated) {
              record._raw._status = 'created';
            }

            for (let i = 0; i < EDITABLE_FIELDS.length; i++) {
              record[EDITABLE_FIELDS[i]] = data[EDITABLE_FIELDS[i]];
            }
          }),
        ),
      );
    });
  }

  static async _editFromMarker(id, data, markerId, database) {
    const { timezone: deviceTimezone } = await database.collections.get('user').find('USER');

    const recurringEventModel = (await database.collections.get('events').query(Q.where('id', id)))[0];
    const recurringEvent = parseRaw(recurringEventModel);

    const chainedRecurringEventModels = await database.collections
      .get('events')
      .query(Q.where('rootId', recurringEvent.rootId));
    const chainedInstanceModels = await database.collections
      .get('events')
      .query(Q.where('rootRecurringEventId', recurringEvent.rootId));
    const eventModels = [...chainedRecurringEventModels, ...chainedInstanceModels];

    const chainedRecurringEvents = chainedRecurringEventModels.map(parseRaw).sort((a, b) => (b.id < a.id ? 1 : -1));
    const chainedInstances = chainedInstanceModels.map(parseRaw).sort((a, b) => (b.id < a.id ? 1 : -1));

    const created = [];
    const updated = [];

    const eventUpdates = CalendarLib.editRecurringEventFromMarker(
      { chainedRecurringEvents, chainedInstances },
      { markerId, data },
      deviceTimezone,
    );

    for (let i = 0; i < eventUpdates.length; i++) {
      const eventUpdate = eventUpdates[i];
      const model = eventModels.find(m => m.id === eventUpdate.id);

      if (model) {
        updated.push({ model, data: eventUpdate });
      } else {
        created.push({ data: eventUpdate });
      }
    }

    await database.action(async () => {
      await database.batch(
        ...created.map(({ data }) =>
          database.collections.get('events').prepareCreate(record => {
            record._raw.id = data.id;

            if (data._generated) {
              record._raw._status = 'synced';
            }

            for (let i = 0; i < EDITABLE_FIELDS.length; i++) {
              record[EDITABLE_FIELDS[i]] = data[EDITABLE_FIELDS[i]];
            }
          }),
        ),
        ...updated.map(({ model, data }) =>
          model.prepareUpdate(record => {
            if (data._generated) {
              record._raw._status = 'synced';
            } else if (record._generated) {
              record._raw._status = 'created';
            }

            for (let i = 0; i < EDITABLE_FIELDS.length; i++) {
              record[EDITABLE_FIELDS[i]] = data[EDITABLE_FIELDS[i]];
            }
          }),
        ),
      );
    });
  }
}

export default Event;
