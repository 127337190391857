import React from 'react';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Q } from '@nozbe/watermelondb';
import { useDatabase } from '../../../data/database';
import useObservable from '../../../hooks/useObservable';

// TODO: partial completions properly

const moment = extendMoment(Moment);

const Container = styled(motion.div)`
  height: 72px;
  margin: 8px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
`;

const Title = styled.div`
  width: 25%;
`;
const TitleText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #202122;
  margin-left: 48px;
`;
const Goal = styled.div`
  width: 15%;
`;
const GoalText = styled.span`
  font-size: 14px;
  color: #202122;
`;

const ProgressContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;
const DayOfWeek = styled(motion.div)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
`;
const DOWText = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.color};
  user-select: none;
`;
const TodayMarker = styled.div`
  position: absolute;
  margin-top: 44px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #27d089;
`;

const computeBgColor = (instance, day, now) => {
  if (day >= now) {
    if (instance) {
      if (instance.value === 0) {
        return '#E9FAF3';
      } else if (instance.value < instance.goal) {
        return 'white';
      } else {
        return '#27D089';
      }
    } else {
      return '#F5F6F7';
    }
  } else {
    if (instance) {
      if (instance.value === 0) {
        return '#FFEEF1';
      } else if (instance.value < instance.goal) {
        return 'white';
      } else {
        return '#27D089';
      }
    } else {
      return '#F5F6F7';
    }
  }
};
const computeTextColor = (instance, day, now) => {
  if (day >= now) {
    if (instance) {
      if (instance.value === 0) {
        return '#27D089';
      } else if (instance.value < instance.goal) {
        return '#27D089';
      } else {
        return 'white';
      }
    } else {
      return '#B1B7C1';
    }
  } else {
    if (instance) {
      if (instance.value === 0) {
        return '#FF5271';
      } else if (instance.value < instance.goal) {
        return '#27D089';
      } else {
        return 'white';
      }
    } else {
      return '#B1B7C1';
    }
  }
};

const ProgressDays = ({ now, days, instances }) => {
  return (
    <ProgressContainer>
      {days.map(day => {
        const dayOfMonth = moment.utc(day).format('D');
        const instance = instances.find(i => i.start === day);

        const bgColor = computeBgColor(instance, day, now);
        const textColor = computeTextColor(instance, day, now);

        return (
          <DayOfWeek key={day} color={bgColor} whileHover={{ scale: 1.25, transition: { duration: 0.2 } }}>
            <DOWText color={textColor}>{dayOfMonth}</DOWText>
            {day === now && <TodayMarker />}
          </DayOfWeek>
        );
      })}
    </ProgressContainer>
  );
};

const Routine = ({ routine }) => {
  const database = useDatabase();

  const user = useObservable(database.collections.get('users').findAndObserve(routine.userId));

  const now = moment.tz(user.timezone).format('YYYY-MM-DD');
  const satM = moment
    .utc(now)
    .startOf('week')
    .subtract(1, 'day');
  const prevSunM = satM
    .clone()
    .subtract(1, 'week')
    .add(1, 'day');

  const days = Array.from(moment.range(prevSunM, satM).by('day')).map(m => m.format('YYYY-MM-DD'));

  const instances = useObservable(
    database.collections
      .get('events')
      .query(
        Q.and(
          Q.where('userId', routine.userId),
          Q.where('rootRecurringEventId', routine.rootEventId),
          Q.where('start', Q.gte(days[0])),
          Q.where('start', Q.lte(days[6])),
        ),
      )
      .observe(),
  );

  return (
    <Container whileHover={{ scale: 1.01, transition: { duration: 0.2 } }}>
      <Title>
        <TitleText>{routine.summary}</TitleText>
      </Title>

      <Goal>
        <GoalText>
          {routine.goal} {routine.unit}
        </GoalText>
      </Goal>

      <ProgressDays now={now} days={days} instances={instances} />
    </Container>
  );
};

export default Routine;
