import moment from 'moment-timezone';
import '@rschedule/moment-tz-date-adapter/setup';
import '@rschedule/moment-tz-date-adapter';
import '@rschedule/core';

import { VEvent as _VEvent, RRule as _RRule } from '@rschedule/ical-tools';

const isDateTime = (date) => !!date.split('T')[1];

export const computeDTStart = (start, timezone) => {
  const m = isDateTime(start) ? moment.utc(start).tz(timezone) : moment.utc(`${start}T02:00:00`);
  return m.format('YYYYMMDDTHHmmss') + 'Z';
};

const computeVEvent = (recurrence, start, timezone) => {
  const dtstart = computeDTStart(start, timezone);

  let rString = recurrence.find((r) => r.includes('RRULE'));
  const match = rString.match('UNTIL=([0-9TZ]*)');

  if (match) {
    const rUntil = match[1].endsWith('Z')
      ? moment.utc(match[1]).tz(timezone).format('YYYYMMDDTHHmmss') + 'Z'
      : `${match[1]}T235959Z`;

    rString = `DTSTART:${dtstart}\n${rString.replace(match[1], rUntil)}`;
  } else {
    rString = `DTSTART:${dtstart}\n${rString}`;
  }

  return _VEvent.fromICal(rString)[0];
};

export const expandRecurrence = (recurrence, start, timezone, { take = 50 } = {}) => {
  const vEvent = computeVEvent(recurrence, start, timezone);

  const mDates = vEvent
    .occurrences({ take })
    .toArray()
    .map((d) => d._date);

  return isDateTime(start) ? mDates.map((m) => moment.tz(m.format('YYYY-MM-DDTHH:mm:ss'), timezone).utc()) : mDates;
};

// TODO: base this off computeVEvent instead
export const getRRuleFromRecurrence = (recurrence, dtStart) => {
  if (recurrence.length === 0) {
    return null;
  }

  let rString = recurrence.find((r) => r.includes('RRULE'));
  const match = rString.match('UNTIL=([0-9TZ]*)');

  if (match) {
    const rUntil = match[1].endsWith('Z') ? match[1] : `${match[1]}T235959Z`;
    rString = `DTSTART:${dtStart}\n${rString.replace(match[1], rUntil)}`;
  } else {
    rString = `DTSTART:${dtStart}\n${rString}`;
  }

  const ve = _VEvent.fromICal(rString)[0];
  return ve.rrules[0];
};

export const getRecurrenceFromRRule = (rule) => {
  if (!rule) {
    return [];
  }

  const ve = new _VEvent({ start: rule.options.start, rrules: [rule] });
  return [ve.toICal().split('\n')[2]];
};

export const RRule = _RRule;
export const VEvent = _VEvent;
