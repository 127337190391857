import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  width: calc(100% - 40px);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: white;
`;

const Input = styled.input`
  outline: none;
  border: none;
  flex: 1;
  padding: 8px;
  color: #222;
  font-size: 14px;
  background-color: #f5f6f7;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

  &::placeholder {
    color: #b1b7c1;
  }
`;

const AllButton = styled(motion.button)`
  outline: none;
  margin-left: 14px;
  padding: 8px 24px;
  color: #b1b7c1;
  background-color: white;
  border: 1px solid #e6e9ef;
  border-radius: 4px;
`;

const SearchControls = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = e => setSearchTerm(e.target.value);
  const handleAllClick = () => setSearchTerm('');

  useEffect(() => onSearch(searchTerm.toLowerCase()), [searchTerm]);

  return (
    <Container>
      <Input placeholder="Search Users" value={searchTerm} onChange={handleChange} />
      <AllButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={handleAllClick}>
        All
      </AllButton>
    </Container>
  );
};

export default SearchControls;
