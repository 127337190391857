import React from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
import { Q } from '@nozbe/watermelondb';
import { useDatabase } from '../../data/database';
import useObservable from '../../hooks/useObservable';
import Routines from './components/Routines';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const ControlBar = styled.div`
  flex-shrink: 0;
  height: 71px;
  border: 1px solid transparent;
  border-bottom-color: #b1b7c130;
  background-color: white;
`;
const Inner = styled.div`
  flex: 1;
`;

const User = () => {
  const database = useDatabase();
  const { params } = useRouteMatch('/users/:id');

  const user = useObservable(database.collections.get('users').findAndObserve(params.id), [params.id]);

  const _routines = useObservable(
    database.collections
      .get('events')
      .query(
        Q.and(
          Q.where('userId', params.id),
          Q.where('eventType', 'routine'),
          Q.where('status', Q.notEq('cancelled')),
          Q.where('recurringEventId', null),
        ),
      )
      .observe(),
    [params.id],
  );
  const routines = _routines.filter(r => r.rootEventId === r.eventId);

  return (
    <Container>
      <ControlBar />
      <Inner>{routines.length > 0 && <Routines routines={routines} />}</Inner>
    </Container>
  );
};

export default User;
