import React from 'react';
import styled from 'styled-components';
import useStore from '../store/hook';
import DemigodLogo from './icons/DemigodLogo';

const Container = styled.div`
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #212121;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PowerButton = styled.div`
  height: 72px;
  width: 100%;
`;

const DataIcon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: white;
`;

const Option = styled.div`
  height: 72px;
  width: calc(100% - 3px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.selected ? '#2f2f2f' : 'transparent')};
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${props => (props.selected ? '#a9baff' : 'transparent')};
`;

const Sidebar = () => {
  const { signOut } = useStore();

  return (
    <Container>
      <Top>
        <Option>
          <DemigodLogo width={34} height={34} color="white" style={{ marginLeft: -3 }} />
        </Option>
        <Option selected>
          <DataIcon style={{ marginLeft: -3 }} />
        </Option>
      </Top>
      <PowerButton onClick={signOut} />
    </Container>
  );
};

export default Sidebar;
