import { appSchema, tableSchema } from '@nozbe/watermelondb';

// TODO: add isIndexed: true to required columns

export default appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: 'events',
      columns: [
        { name: 'userId', type: 'string' },
        { name: 'eventId', type: 'string' },
        { name: 'calendarId', type: 'string' },
        { name: 'rootEventId', type: 'string', isOptional: true },
        { name: 'recurringEventId', type: 'string', isOptional: true },
        { name: 'rootRecurringEventId', type: 'string', isOptional: true },
        { name: 'isAllDay', type: 'boolean' },
        { name: 'start', type: 'string' },
        { name: 'end', type: 'string' },
        { name: 'recurrence', type: 'string' },
        { name: 'timezone', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'summary', type: 'string' },
        { name: 'organizer', type: 'string' },
        { name: 'location', type: 'string', isOptional: true },
        { name: 'description', type: 'string', isOptional: true },
        { name: 'reminders', type: 'string' },
        { name: 'color', type: 'string' },
        { name: 'attendees', type: 'string' },
        { name: 'responseStatus', type: 'string', isOptional: true },

        { name: 'eventType', type: 'string' },
        { name: 'archived', type: 'boolean' },
        { name: 'conference', type: 'string', isOptional: true },
        { name: 'subtasks', type: 'string', isOptional: true },
        { name: 'icon', type: 'string', isOptional: true },
        { name: 'value', type: 'number', isOptional: true },
        { name: 'goal', type: 'number', isOptional: true },
        { name: 'unit', type: 'string', isOptional: true },
        { name: 'time', type: 'string', isOptional: true },

        { name: 'order', type: 'number', isOptional: true },
        { name: 'lastModified', type: 'string' },
        { name: '_generated', type: 'boolean' },
      ],
    }),
    tableSchema({
      name: 'google_accounts',
      columns: [
        { name: 'userId', type: 'string' },
        { name: 'calendarId', type: 'string' },
      ],
    }),
    tableSchema({
      name: 'users',
      columns: [
        { name: 'email', type: 'string', isOptional: true },
        { name: 'name', type: 'string', isOptional: true },
        { name: 'timezone', type: 'string' },
        { name: 'onboardingComplete', type: 'boolean' },
        { name: 'stripeData', type: 'string' },
        { name: 'interestInProPlan', type: 'boolean', isOptional: true },
      ],
    }),
    tableSchema({
      name: 'coach',
      columns: [
        { name: 'coachId', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'name', type: 'string' },
      ],
    }),
  ],
});
