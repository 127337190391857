import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import useStore from '../store/hook';
import Login from './Login';
import Home from './Home';

const Loading = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
`;

const Root = () => {
  const { signedIn } = useStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <Switch>
      {loading ? (
        <Route path="/" component={Loading} />
      ) : signedIn ? (
        <Route path="/" component={Home} />
      ) : (
        <Route exact path="/" component={Login} />
      )}
      <Redirect to="/" />
    </Switch>
  );
};

export default Root;
