import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

class GoogleAccount extends Model {
  static table = 'google_accounts';

  @field('userId') userId;
  @field('calendarId') calendarId;
}

export default GoogleAccount;
