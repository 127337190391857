import _ from 'lodash';
import moment from 'moment-timezone';
import qs from 'query-string';
import { synchronize } from '@nozbe/watermelondb/sync';
import { Q } from '@nozbe/watermelondb';
import { useDatabase as _useDatabase } from './database';
import * as CalendarLib from '../lib/calendar';
import call from '../utils/call';

const JSON_FIELDS = ['recurrence', 'organizer', 'attendees', 'reminders', 'subtasks', 'conference'];

const retryOnce = fn =>
  new Promise((resolve, reject) =>
    fn()
      .then(resolve)
      .catch(() =>
        fn()
          .then(resolve)
          .catch(reject),
      ),
  );

const isDateTime = date => !!date.split('T')[1];
const convertLocalDateToUTC = (date, timezone) =>
  isDateTime(date)
    ? moment
        .tz(date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss')
    : date;
const convertUTCDateToLocal = (date, timezone) =>
  isDateTime(date)
    ? moment
        .utc(date)
        .tz(timezone)
        .format('YYYY-MM-DDTHH:mm:ss')
    : date;

const transformRemoteToLocal = (remoteEvent, deviceTimezone) => {
  for (let i = 0; i < JSON_FIELDS.length; i++) {
    const field = JSON_FIELDS[i];
    if (!_.isNil(remoteEvent[field])) {
      remoteEvent[field] = JSON.stringify(remoteEvent[field]);
    }
  }

  const start = convertUTCDateToLocal(remoteEvent.start, deviceTimezone);
  const end = convertUTCDateToLocal(remoteEvent.end, deviceTimezone);
  const isAllDay = !isDateTime(start);

  const localEvent = _.omitBy(
    {
      ...remoteEvent,
      rootEventId: remoteEvent.recurringEventId ? null : remoteEvent.eventId.split('_R')[0],
      rootRecurringEventId: remoteEvent.recurringEventId ? remoteEvent.recurringEventId.split('_R')[0] : null,
      start,
      end,
      isAllDay,
      _generated: false,
    },
    _.isNil,
  );

  return localEvent;
};

const transformLocalToRemote = (localEvent, deviceTimezone) => {
  let remoteEvent = {};

  if (localEvent._status === 'created') {
    remoteEvent = { ...localEvent };
  } else {
    const changed = localEvent._changed.split(',');

    for (let i = 0; i < changed.length; i++) {
      remoteEvent[changed[i]] = localEvent[changed[i]];
    }
  }

  remoteEvent.id = localEvent.id;
  remoteEvent.eventId = localEvent.eventId;
  remoteEvent.calendarId = localEvent.calendarId;
  remoteEvent.userId = localEvent.userId;

  if (remoteEvent.start) {
    remoteEvent.start = convertLocalDateToUTC(remoteEvent.start, deviceTimezone);
  }
  if (remoteEvent.end) {
    remoteEvent.end = convertLocalDateToUTC(remoteEvent.end, deviceTimezone);
  }

  for (let i = 0; i < JSON_FIELDS.length; i++) {
    const field = JSON_FIELDS[i];

    if (remoteEvent[field]) {
      remoteEvent[field] = JSON.parse(remoteEvent[field]);
    }
  }

  return _.omit(_.omitBy(remoteEvent, _.isNil), [
    '_status',
    '_changed',
    '_generated',
    'isAllDay',
    'rootEventId',
    'rootRecurringEventId',
    'lastModified',
  ]);
};

export default async () => {
  const database = _useDatabase();
  const session = JSON.parse(localStorage.getItem('session'));

  try {
    await retryOnce(() =>
      synchronize({
        database,
        pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
          const q = qs.stringify({ lastPulledAt, schemaVersion, migration }, { skipNull: true });
          const { changes, timestamp } = await call('GET', `coaches/${session.coachId}/sync?${q}`);

          // TODO: for deleted users we also need to wipe the google_accounts and events
          changes.users.updated = changes.users.updated.map(u => ({ ...u, stripeData: JSON.stringify(u.stripeData) }));

          const users = [
            ...(await database.collections
              .get('users')
              .query(Q.where('id', Q.oneOf(_.uniq(changes.events.updated.map(e => e.userId)))))
              .fetch()),
            ...changes.users.updated,
          ];

          const updatedEvents = [];

          for (let i = 0; i < changes.events.updated.length; i++) {
            const user = users.find(u => u.id === changes.events.updated[i].userId);
            const eventUpdate = transformRemoteToLocal(changes.events.updated[i], user.timezone);

            const idx = updatedEvents.findIndex(e => e.id === eventUpdate.id);
            if (idx >= 0) {
              updatedEvents[idx] = eventUpdate;
            } else {
              updatedEvents.push(eventUpdate);
            }

            // This generates instances for an eventUpdate
            const recurrence = JSON.parse(eventUpdate.recurrence);
            const instances = CalendarLib.generateInstances({ ...eventUpdate, recurrence }, user.timezone).map(e => ({
              ...e,
              recurrence: JSON.stringify(e.recurrence),
            }));

            // Add in new instances
            for (let j = 0; j < instances.length; j++) {
              if (!updatedEvents.find(e => e.id === instances[j].id)) {
                updatedEvents.push(instances[j]);
              }
            }

            // Remove cancelled instances
            const cancelledInstances = await database.collections
              .get('events')
              .query(
                Q.and(
                  Q.where('userId', user.id),
                  Q.where('recurringEventId', eventUpdate.eventId),
                  Q.where('_generated', true),
                  Q.where('id', Q.notIn(instances.map(e => e.id))),
                ),
              )
              .fetch();
            for (let j = 0; j < cancelledInstances.length; j++) {
              updatedEvents.push({ id: cancelledInstances[j].id, status: 'cancelled' });
            }
          }

          changes.events = { ...changes.events, updated: updatedEvents };

          return { changes, timestamp };
        },
        pushChanges: async ({ changes }) => {
          console.log(changes);
        },
        sendCreatedAsUpdated: true,
      }),
    );
  } catch (err) {
    console.log(err);
  }
};
