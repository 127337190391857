import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import SearchControls from './SearchControls';
import User from './User';

const Container = styled.div`
  width: 320px;
  background-color: white;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
`;
const List = styled.div`
  height: calc(100% - 72px);
  overflow: scroll;
`;

const UsersBar = ({ users }) => {
  const history = useHistory();
  const match = useRouteMatch('/users/:id');
  const [searchTerm, setSearchTerm] = useState('');

  const selectedUserId = match?.params?.id;
  const filteredUsers = users.filter(
    u => u.name.toLowerCase().startsWith(searchTerm) || u.email.toLowerCase().startsWith(searchTerm),
  );

  const handleSelect = user => history.push(`/users/${user.id}`);

  return (
    <Container>
      <SearchControls onSearch={setSearchTerm} />
      <List>
        {filteredUsers.map(u => (
          <User key={u.id} user={u} selected={u.id === selectedUserId} onSelect={handleSelect} />
        ))}
      </List>
    </Container>
  );
};

export default UsersBar;
