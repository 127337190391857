import React from 'react';
import styled from 'styled-components';
import Routine from './Routine';

const Container = styled.div`
  margin-top: 24px;
  padding: 12px 24px;
  height: 90%;
  overflow: scroll;
`;
const TableHeaders = styled.div`
  padding-bottom: 3px;
  display: flex;
  align-items: center;
`;

const HText = styled.span`
  font-size: 13px;
  font-weight: 700;
  color: #b1b7c1;
`;
const TitleHeader = styled.div`
  width: 25%;
`;
const GoalHeader = styled.div`
  width: 15%;
`;

const DOWContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;
const DaysOfWeek = () => {
  return (
    <DOWContainer>
      <HText>S</HText>
      <HText>M</HText>
      <HText>T</HText>
      <HText>W</HText>
      <HText>T</HText>
      <HText>F</HText>
      <HText>S</HText>
    </DOWContainer>
  );
};

const EmptyHeader = styled.span`
  width: 20%;
`;

const Routines = ({ routines }) => {
  return (
    <Container>
      <TableHeaders>
        <TitleHeader>
          <HText style={{ marginLeft: 48 }}>Title</HText>
        </TitleHeader>
        <GoalHeader>
          <HText>Goal</HText>
        </GoalHeader>
        <DaysOfWeek />
        <EmptyHeader />
      </TableHeaders>

      {routines.map(r => (
        <Routine key={r.id} routine={r} />
      ))}
    </Container>
  );
};

export default Routines;
