import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

class Coach extends Model {
  static table = 'coach';

  @field('coachId') coachId;
  @field('email') email;
  @field('name') name;
}

export default Coach;
