import { Model } from '@nozbe/watermelondb';
import { field, json } from '@nozbe/watermelondb/decorators';

class User extends Model {
  static table = 'users';

  @field('email') email;
  @field('name') name;
  @field('timezone') timezone;
  @field('onboardingComplete') onboardingComplete;
  @json('stripeData', r => r) stripeData;
  @field('interestInProPlan') interestInProPlan;
}

export default User;
