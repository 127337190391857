import * as React from 'react';

export default props => {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.048 16.272c-.76 6.315-4.343 11.479-7.845 16.272-3.503 4.794-6.896 9.127-7.166 13.54a17.073 17.073 0 009.007 16.193C26.18 67.28 35.248 70.563 40.732 80c5.334-9.357 14.57-12.78 23.637-17.723a17.073 17.073 0 008.907-16.192c-.68-10.268-15.081-17.413-14.921-29.813-6.785 7.906-5.414 13.75-3.052 18.754 2.361 5.004 5.794 9.007 3.122 13.23a.89.89 0 01-.23.31.999.999 0 01-.22.3 6.006 6.006 0 01-2.292 1.902 6.355 6.355 0 01-3.123.76 6.795 6.795 0 01-3.732-12.499L40.682 0l-8.157 39.03a6.815 6.815 0 01-3.802 12.469 6.095 6.095 0 01-3.053-.76 6.314 6.314 0 01-2.361-1.902 1 1 0 01-.22-.3.39.39 0 01-.16-.31c-5.405-8.537 13.56-16.203.12-31.955z"
        fill={props.color}
      />
    </svg>
  );
};
