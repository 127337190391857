import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import StoreProvider from './store/Provider';
import { createDatabase } from './data/database';
import Root from './screens/Root';

createDatabase();

const App = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <StoreProvider>
          <Root />
        </StoreProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default App;
