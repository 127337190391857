import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useStore from '../store/hook';
import DemigodLogo from '../components/icons/DemigodLogo';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f6f7;
`;

const Inner = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 36px;
  border-radius: 16px;
  -webkit-box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.3);
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
`;
const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: black;
  margin: 2px 0 0 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font-size: 12px;
  color: #aaa;
  font-weight: 600;
  margin-left: 8px;
  margin-bottom: 8px;
`;
const Input = styled.input`
  margin-bottom: 28px;
  padding: 16px;
  border-radius: 12px;
  outline: none;
  border: none;
  -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);

  &:focus {
    -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
  &:hover {
    -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`;
const Button = styled(motion.input)`
  margin-top: 6px;
  padding: 16px;
  border-radius: 12px;
  outline: none;
  border: none;
  background-color: #2f2f2f;
  color: white;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);

  &:focus {
    -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
  &:hover {
    -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`;

const Login = () => {
  const { signIn } = useStore();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);
  const handleSignIn = e => {
    e.preventDefault();
    signIn({ email, password });
  };

  return (
    <Container>
      <Inner>
        <TitleContainer>
          <DemigodLogo width={42} height={42} color="black" />
          <Title>Demigod Coach Portal</Title>
        </TitleContainer>

        <Form onSubmit={handleSignIn}>
          <Label>EMAIL</Label>
          <Input placeholder="Enter email" value={email} onChange={handleEmailChange} />
          <Label>PASSWORD</Label>
          <Input placeholder="Enter password" type="password" value={password} onChange={handlePasswordChange} />
          <Button type="submit" whileHover={{ scale: 1.01 }} value="SIGN IN" />
        </Form>
      </Inner>
    </Container>
  );
};

export default Login;
