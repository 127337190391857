const CONFIG_DEV = {
  API_BASE: 'http://localhost:7074',
  GOOGLE_PLACES_API_KEY: 'AIzaSyBXZu1j2qBH_igzayfqu2lYvrIvQ0VL2EI',
};
const CONFIG_STAG = {
  API_BASE: 'https://api.demigod.work',
  GOOGLE_PLACES_API_KEY: 'AIzaSyBXZu1j2qBH_igzayfqu2lYvrIvQ0VL2EI',
};
const CONFIG_PROD = {
  API_BASE: 'https://api.demigod.ai',
  GOOGLE_PLACES_API_KEY: 'AIzaSyBXZu1j2qBH_igzayfqu2lYvrIvQ0VL2EI',
};

const ENV = process.env.REACT_APP_ENVIRONMENT || 'development';
const CONF = ENV === 'production' ? CONFIG_PROD : ENV === 'staging' ? CONFIG_STAG : CONFIG_DEV;

export const apiBase = CONF.API_BASE;
export const googlePlacesApiKey = CONF.GOOGLE_PLACES_API_KEY;
