import _ from 'lodash';
import moment from 'moment-timezone';
import { alphaid } from './uuid';
import { expandRecurrence } from './recurrence';

const isDateTime = date => !!date.split('T')[1];

const convertLocalDateToUTC = (date, deviceTimezone) =>
  isDateTime(date)
    ? moment
        .tz(date, deviceTimezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss')
    : date;

const setUntilOnRecurrence = (recurrence, date, deviceTimezone) => {
  let newUntil;
  if (isDateTime(date)) {
    newUntil =
      moment
        .tz(date, deviceTimezone)
        .startOf('day')
        .subtract(1, 'second')
        .utc()
        .format('YYYYMMDDTHHmmss') + 'Z';
  } else {
    newUntil = moment
      .utc(date)
      .subtract(1, 'day')
      .format('YYYYMMDD');
  }

  const ruleString = recurrence[0].split(';UNTIL=')[0];
  const newRuleString = `${ruleString};UNTIL=${newUntil}`;

  return [newRuleString];
};

const generateChainId = (markerRecurringEvent, markerInstance, deviceTimezone) => {
  const subId = isDateTime(markerInstance.start)
    ? moment
        .tz(markerInstance.start, deviceTimezone)
        .utc()
        .format('YYYYMMDDTHHmmss')
    : moment.utc(markerInstance.start).format('YYYYMMDD');

  return `${markerRecurringEvent.rootEventId}_R${subId}`;
};

const cloneAndUpdate = (event, ...updates) => Object.assign({}, event, ...updates);

export const generateInstances = (event, deviceTimezone) => {
  if (event.recurrence.length === 0 || event.recurringEventId) {
    return [];
  }

  const start = convertLocalDateToUTC(event.start, deviceTimezone);
  const end = convertLocalDateToUTC(event.end, deviceTimezone);

  const instances = [];

  const mDates = expandRecurrence(event.recurrence, start, event.timezone);

  const dts = !event.isAllDay
    ? mDates.map(m => m.format('YYYYMMDDTHHmmss') + 'Z')
    : mDates.map(m => m.format('YYYYMMDD'));

  const eventDuration = !event.isAllDay
    ? moment.utc(end).diff(moment.utc(start), 'minutes')
    : moment.utc(end).diff(moment.utc(start), 'days');

  for (let i = 0; i < dts.length; i++) {
    const e = {};

    e.eventId = `${event.rootEventId}_${dts[i]}`;
    e.id = `${event.calendarId}_${e.eventId}`;
    e.recurringEventId = event.eventId;
    e.rootRecurringEventId = event.eventId.split('_R')[0];
    e.userId = event.userId;

    e.isAllDay = event.isAllDay;
    e.start = !event.isAllDay
      ? mDates[i].tz(deviceTimezone).format('YYYY-MM-DDTHH:mm:ss')
      : mDates[i].format('YYYY-MM-DD');
    e.end = !event.isAllDay
      ? mDates[i]
          .clone()
          .add(eventDuration, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss')
      : mDates[i]
          .clone()
          .add(eventDuration, 'days')
          .format('YYYY-MM-DD');

    e.recurrence = [];
    e._generated = true;

    const keys = Object.keys(event).filter(
      k =>
        ![
          'id',
          'rootEventId',
          'recurringEventId',
          'rootRecurringEventId',
          'isAllDay',
          'start',
          'end',
          'recurrence',
          '_generated',
        ].includes(k),
    );
    for (let j = 0; j < keys.length; j++) {
      e[keys[j]] = event[keys[j]];
    }

    instances.push(e);
  }

  return instances;
};

export const createEvent = ({ data }, deviceTimezone) => {
  const eventId = alphaid();

  const newEvent = Object.assign(
    { id: `${data.calendarId}_${eventId}`, eventId, rootEventId: id, status: 'confirmed' },
    data,
  );

  return [newEvent, ...generateInstances(newEvent, deviceTimezone)];
};

export const editEvent = ({ event }, { data }, deviceTimezone) => {
  const newEvent = cloneAndUpdate(event, data);

  return [newEvent, ...generateInstances(newEvent, deviceTimezone)];
};

// TODO: cancel entire recurring event if we cancel the last active instance
export const editEventInstance = ({ chainedRecurringEvents, chainedInstances }, { id, data }) => {
  const instance = chainedInstances.find(i => i.id === id);
  const newInstance = cloneAndUpdate(instance, { _generated: false }, data);

  return [newInstance];
};

export const editRecurringEventFromMarker = (
  { chainedRecurringEvents, chainedInstances },
  { markerId, data },
  deviceTimezone,
) => {
  const eventUpdates = [];

  const markerIdx = chainedInstances.map(i => i.id).indexOf(markerId);
  const markedInstances = markerIdx >= 0 ? chainedInstances.slice(markerIdx) : chainedInstances;

  const markerInstance = markedInstances[0];
  const markerRecurringEvent = chainedRecurringEvents.find(e => e.eventId === markerInstance.recurringEventId);

  if (data.start || data.recurrence) {
    // We are editing critical fields of the recurrence

    let recurringEvent; // this should be the first recurring event for markedInstances
    let newInstances; // this is a list of new instances resulting from the recurrence update
    if (markerRecurringEvent.start === markerInstance.start) {
      // We are editing from start of a recurring event

      if (markerRecurringEvent.eventId === markerRecurringEvent.rootEventId) {
        // We are from the start of the event chain

        const _markerRecurringEvent = cloneAndUpdate(markerRecurringEvent, data);
        eventUpdates.push(_markerRecurringEvent);

        newInstances = generateInstances(_markerRecurringEvent, deviceTimezone);
        recurringEvent = _markerRecurringEvent;
      } else {
        // We are editing from inside the event chain

        // We are creating a new event where marker was without a chain following it - the recurrence will need to be set to the last one in the current chain
        // To get the last recurring event in the chain we need to grab the last event in chainedRecurringEvents
        const lastInChain = chainedRecurringEvents.slice(-1);

        // recurrence of new recurring event should be that of lastChained
        const newId = alphaid();
        const newRecurringEvent = cloneAndUpdate(
          markerRecurringEvent,
          {
            id: `${markerRecurringEvent.calendarId}_${newId}`,
            eventId: newId,
            rootEventId: newId,
            recurrence: lastInChain.recurrence,
          },
          data,
        );

        // This needs cancelling as we have replaced it
        const _markerRecurringEvent = cloneAndUpdate(markerRecurringEvent, { status: 'cancelled' });
        eventUpdates.push(newRecurringEvent, _markerRecurringEvent);

        newInstances = generateInstances(newRecurringEvent, deviceTimezone);
        recurringEvent = _markerRecurringEvent;
      }
    } else {
      // We are editing from the middle of a recurring event

      const lastInChain = chainedRecurringEvents.slice(-1);

      // Here we create a new event that starts from the middle of the markerRecurringEvent. It will have no chain following but must use the recurrence
      // of the lastInChain
      const newId = alphaid();
      const newRecurringEvent = cloneAndUpdate(
        markerRecurringEvent,
        {
          id: `${markerRecurringEvent.calendarId}_${newId}`,
          eventId: newId,
          rootEventId: newId,
          start: markerInstance.start,
          end: markerInstance.end,
          recurrence: lastInChain.recurrence,
        },
        data,
      );

      // This ends the markerRecurringEvent just before the markerInstance
      // TODO: this is a bug. When changing the start to have a time the markerInstance is to cause the UNTIL
      // value to be wrong.

      const _markerRecurringEvent = cloneAndUpdate(markerRecurringEvent, {
        recurrence: setUntilOnRecurrence(markerRecurringEvent.recurrence, markerInstance.start, deviceTimezone),
      });
      eventUpdates.push(newRecurringEvent, _markerRecurringEvent);

      newInstances = generateInstances(newRecurringEvent, deviceTimezone);
      recurringEvent = _markerRecurringEvent;
    }

    // Create new instances and update existing
    for (let i = 0; i < newInstances.length; i++) {
      const newInstance = newInstances[i];

      const instance = markedInstances.find(e => e.id === newInstance.id);
      if (!instance) {
        eventUpdates.push(newInstance);
      } else {
        eventUpdates.push(
          cloneAndUpdate(instance, _.omit(data, ['start', 'end', 'recurrence']), {
            recurringEventId: newInstance.recurringEventId,
            rootRecurringEventId: newInstance.rootRecurringEventId,
            start: newInstance.start,
            end: newInstance.end,
          }),
        );
      }
    }

    // Cancel instances and recurring events that no longer exist
    for (let i = 0; i < markedInstances.length; i++) {
      const oldInstance = markedInstances[i];

      if (oldInstance.recurringEventId !== recurringEvent.eventId) {
        recurringEvent = chainedRecurringEvents.find(e => e.eventId === oldInstance.recurringEventId);
        eventUpdates.push(cloneAndUpdate(recurringEvent, { status: 'cancelled' }));
      }

      const instance = newInstances.find(e => e.id === oldInstance.id);
      if (!instance) {
        eventUpdates.push(cloneAndUpdate(oldInstance, { status: 'cancelled' }));
      }
    }
  } else {
    // We are just editing safe fields

    let recurringEvent;

    if (markerRecurringEvent.start === markerInstance.start) {
      // We are editing from the start of the recurring event

      const _markerRecurringEvent = cloneAndUpdate(markerRecurringEvent, data);
      eventUpdates.push(_markerRecurringEvent);

      recurringEvent = _markerRecurringEvent;

      // Update recurring events and instances in rest of chain
      for (let i = 0; i < markedInstances.length; i++) {
        const instance = markedInstances[i];

        if (instance.recurringEventId !== recurringEvent.eventId) {
          recurringEvent = chainedRecurringEvents.find(e => e.eventId === instance.recurringEventId);
          eventUpdates.push(cloneAndUpdate(recurringEvent, data));
        }

        eventUpdates.push(cloneAndUpdate(instance, data));
      }
    } else {
      // We are editing from the middle of the recurring event

      const chainId = generateChainId(markerRecurringEvent, markerInstance, deviceTimezone);
      const newRecurringEvent = cloneAndUpdate(
        markerRecurringEvent,
        {
          id: `${markerRecurringEvent.calendarId}_${chainId}`,
          eventId: chainId,
          start: markerInstance.start,
          end: markerInstance.end,
        },
        data,
      );

      const _markerRecurringEvent = cloneAndUpdate(markerRecurringEvent, {
        recurrence: setUntilOnRecurrence(markerRecurringEvent.recurrence, markerInstance.start, deviceTimezone),
      });

      eventUpdates.push(newRecurringEvent, _markerRecurringEvent);

      recurringEvent = markerRecurringEvent;
      for (let i = 0; i < markedInstances.length; i++) {
        const instance = markedInstances[i];

        if (instance.recurringEventId === markerRecurringEvent.eventId) {
          eventUpdates.push(cloneAndUpdate(instance, { recurringEventId: newRecurringEvent.eventId }, data));
        } else if (instance.recurringEventId !== recurringEvent.eventId) {
          recurringEvent = chainedRecurringEvents.find(e => e.eventId === instance.recurringEventId);
          eventUpdates.push(cloneAndUpdate(recurringEvent, data), cloneAndUpdate(instance, data));
        } else {
          eventUpdates.push(cloneAndUpdate(instance, data));
        }
      }
    }
  }

  return eventUpdates;
};
