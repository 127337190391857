import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import User from './User';
import Sidebar from '../../components/Sidebar';
import UsersBar from '../../components/UsersBar';
import useStore from '../../store/hook';
import { useDatabase } from '../../data/database';
import useObservable from '../../hooks/useObservable';

const Container = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row-reverse;
`;
const Inner = styled.div`
  width: calc(100% - 400px);
  background-color: #f5f6f7;
`;

const Home = () => {
  const database = useDatabase();
  const history = useHistory();

  const users = useObservable(
    database.collections
      .get('users')
      .query()
      .observeWithColumns(['timezone', 'onboardingComplete']),
  );

  useEffect(() => {
    history.push(`/users/${users[0].id}`);
  }, []);

  return (
    <Container>
      <Inner>
        <Switch>
          <Route path="/users/:id" children={<User />} />
        </Switch>
      </Inner>
      <UsersBar users={users} />
      <Sidebar />
    </Container>
  );
};

export default Home;
