import { Database } from '@nozbe/watermelondb';
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';
import schema from './schema';
// import migrations from './migrations';
import Coach from './models/Coach';
import Event from './models/Event';
import GoogleAccount from './models/GoogleAccount';
import User from './models/User';

let database;
export const useDatabase = () => database;
export const createDatabase = () => {
  const adapter = new LokiJSAdapter({
    schema,
    dbName: 'demigod',
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    // migrations,
  });

  database = new Database({
    adapter,
    modelClasses: [Event, GoogleAccount, User, Coach],
    actionsEnabled: true,
  });
  return database;
};
